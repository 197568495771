<template>
  <div style="min-height: 500px">
    <BreadCrumbs>خانه > دانلود اپلیکیشن </BreadCrumbs>
    <transition enter-active-class="animate__animated animate__fadeIn"
                appear>
      <div>
        <v-container class="px-0 mt-10">

          <ContantBlock class="mb-8" >
            <img slot="image" src="../assets/image/Public Icons/Side icons/icon4.png"/>
            <span slot="title" >دانلود اپلیکیشن</span>
            <div slot="contant" >

              <div v-html="html" ></div>

              <div class="pb-6 d-flex justify-center flex-wrap">


              <img alt="لوگوی ایران آی سیب" title="ایران آی سیب" src="../assets/image/Download/logo.png" width="40%" style="min-width: 250px" />


              <div class="d-flex flex-wrap align-center justify-center" >
              <img :src="$store.state.upload + qrCode" class="qr-box" height="200" width="200"/>
              <div class="mr-1 mr-sm-6 d-flex flex-column align-center">
                <a v-for="(link,i) in links" :key="i" :href="link.url" target="_blank">
                  <img :src="$store.state.thumbnail + link.image" height="46" width="155"/>
                </a>
<!--              <a :href="links.sibappLink" target="_blank">
              <img src="../assets/image/Footer/sibapp.png" height="45" width="154"/>
              </a>-->
              </div>
              </div>
            </div>
            </div>

          </ContantBlock>

        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import ContantBlock from "@/components/ContantBlock";
import axios from "axios";

export default {
  name: 'App',

  data() {
    return {
      html : null,
      links : null,
      qrCode : null,
    }
  },
  metaInfo() {
    return {
      title:'دانلود اپلیکیشن',
      link: [{rel: 'canonical', href: this.$store.state.url+'/download'}]
    }
  },
  components : {
    BreadCrumbs,
    ContantBlock,
  },
  created() {
    axios.get(this.$store.state.api + 'downloadapppage').then(response=>{
      this.html = response.data.html;
      this.links = response.data.links;
      this.qrCode = response.data.qrCode;
    });
  }

};
</script>


<style lang="scss">
.qr-box{
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 14%), 0 6px 10px 0 rgb(0 0 0 / 10%), 0 1px 18px 0 rgb(0 0 0 / 8%);
  margin-bottom: 10px;
}
</style>
